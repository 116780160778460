'use strict';

import { HelperService } from '../../../core/services/helper.service';
import { IActivityFilterData, IActivityFilterAdditionalData } from '../../../core/contracts/activity.contract';
import { ActivityService } from '../../../core/dataservices/activity.service';
import { ActivityFilterService } from '../../../core/services/activityFilter.service';
import { ActivityHelperService } from '../../../core/services/activityHelper.service';
import { CurrentPageInformation } from '../../../core/services/currentPageInformation';
import { SquareActivitiesDragService } from '../squareActivitiesDrag.service';
import { AuthService } from '../../../core/dataservices/auth.service';
import { ActivitiesOverviewControllerBase } from '../common/activitiesOverviewControllerBase';
import { SpinnerService } from '../../../core/services/spinner.service';
import { ConstantsFactory } from '../../../core/constants.factory';
import { ServerConstants } from '../../../core/serverconstants';
import { DateFormatService } from '../../../core/services/dateformat.service';
import { FeatureService } from '../../../core/dataservices/feature.service';
import { ConversationEventsService } from '../../../core/services/conversationEvents.service';
import { CurrentUserService } from 'src/app/core/dataservices/currentUser.service';

export class ActivitiesQualTabController extends ActivitiesOverviewControllerBase {
  static $inject = ['$stateParams', 'serverConstants', 'activityservice', 'spinnerservice', 'activityHelperService',
    'helperservice', 'squareActivitiesDragService', 'authService', 'constantsfactory',
    '$mdDialog', 'logger', 'dateFormatService', '$location', '$rootScope', '$state', 'featureservice', 'conversationEventsService', 'activityFilterService',
    'currentUserService'];

  activities = [];
  activityStatusFilter = ['Draft', 'Scheduled', 'Active', 'Closed'];
  squareActivitiesComponent;
  qualActivityTypes = [
    this.serverConstants.squareActivityTypeConstants.qualitativeResearch,
    this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch,
    this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch,
    this.serverConstants.squareActivityTypeConstants.publicScoutResearch,
    this.serverConstants.squareActivityTypeConstants.privateScoutResearch,
    this.serverConstants.squareActivityTypeConstants.discussion20Research,
    this.serverConstants.squareActivityTypeConstants.observation,
  ];
  matches = -1;
  matchesFrom: -1;
  hierarchicalActivities = [];
  treeOptions;
  isDragEnabled = true;
  type = this.serverConstants.searchTypeConstants.activity;
  showAnalysisAndReporting: boolean;
  anyActivityWithModeratorCurationEnabled: boolean = false;
  isSavingUpEnabled: boolean;
  isNewRepliesEnabled: boolean;

  constructor(
    $stateParams: ng.ui.IStateParamsService,
    serverConstants: ServerConstants,
    protected activityservice: ActivityService,
    protected spinnerservice: SpinnerService,
    private activityHelperService: ActivityHelperService,
    private helperservice: HelperService,
    private squareActivitiesDragService: SquareActivitiesDragService,
    private authService: AuthService,
    constantsfactory: ConstantsFactory,
    $mdDialog: ng.material.IDialogService,
    logger: Logger,
    dateFormatService: DateFormatService,
    $location: ng.ILocationService,
    $rootScope: ng.IRootScopeService,
    $state: ng.ui.IStateService,
    featureservice: FeatureService,
    private conversationEventsService: ConversationEventsService,
    activityFilterService: ActivityFilterService,
    currentUserService: CurrentUserService,
  ) {
    super(activityservice,
      constantsfactory,
      serverConstants,
      $mdDialog,
      spinnerservice,
      logger,
      dateFormatService,
      $location,
      $rootScope,
      $stateParams,
      $state,
      featureservice,
      activityFilterService,
      currentUserService);
  }

  async $onInit() {
    this.isSavingUpEnabled = await this.featureservice.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards);
    this.isNewRepliesEnabled = !await this.featureservice.isMasterFeatureEnabledForSquare(this.serverConstants.featureConstants.noUnreadConversations);
    this.showAnalysisAndReporting = await this.isAnalysisAndReportingEnabled();
    this.parseParameters();
    this.getLastValidDate();
    this.treeOptions = this.squareActivitiesDragService.getDragOptions(() => {
      this.loadActivities(false);
    }, () => this.getCurrentPageInfo());
  }

  async getLastValidDate() {
    this.activityservice.getFilterDateRangeForActivity(this.qualActivityTypes).then((response) => {
      this.minStartDate = response.data.StartDate;
      this.maxEndDate = response.data.EndDate;
      this.setInitialFilterData();
    });
  }

  getCurrentPageInfo(): CurrentPageInformation {
    const currentPageInformation: CurrentPageInformation = {
      pagination: this.pagination,
    };
    return currentPageInformation;
  }

  async setInitialFilterData() {
    this.filterAdditionalData = this.activityFilterService.createQualFilterAdditional(this.minStartDate, this.maxEndDate) as IActivityFilterAdditionalData;
    this.filterData = this.activityFilterService.getFilterFromQueryString(this.minStartDate, this.maxEndDate, this.qualActivityTypes) as IActivityFilterData;
    this.squareActivitiesComponent.filterData = this.filterData;
    if (this.filterData.Statuses.length === 0) {
      this.filterData.Statuses = this.activityStatusFilter;
    }

    this.loadActivities();
  }

  loadActivities(dragStatusWhileLoading: boolean = true) {
    this.isDragEnabled = dragStatusWhileLoading;
    const request = JSON.parse(JSON.stringify(this.filterData));
    this.spinnerservice.show('loading');
    this.activityservice.getSquareActivities(this.qualActivityTypes, request, this.pagination.page, this.pagination.limit, this.order)
      .then((activities) => {
        if (activities && activities.List && activities.List.Items) {
          this.pagination.total = activities.List.TotalItems;
          this.activityHelperService.convertEnumsToStringForActivities(activities.List.Items);
          this.hierarchicalActivities = activities.List.Items;
          this.activities = this.activityHelperService.getFlattenedActivities(activities.List.Items);
          this.activities.forEach((activity) => {
            if (activity.EndDate) {
              activity.EndDate = this.dateFormatService.getDateTime(activity.EndDate, activity.Offset);
            }

            this.setDownloadParams(activity, this.filterData);
          });
          this.anyActivityWithModeratorCurationEnabled = this.activities.some((activity) => activity.ModeratorCuration);
          // We need to set isModeratorCurationEnabled for the filter additional data object.
          // At the moment, It is used to determine if the Moderated(accepted) option is shown in the Moderation Status filter
          this.filterAdditionalData = this.activityFilterService.createQualFilterAdditional(this.filterAdditionalData.MinStartDate,
            this.filterAdditionalData.MaxEndDate, this.anyActivityWithModeratorCurationEnabled);
          this.conversationEventsService.filterAdditionalDataChange.next(this.filterAdditionalData);

          this.filterDataCounters = activities.FilterDataCounters;
          this.matches = activities.Matches;
          this.matchesFrom = activities.MatchesFrom;
        }
      })
      .finally(() => {
        this.spinnerservice.hide('loading');
        this.isDragEnabled = true;
      });
  }

  statusFilter = ((activity) => this.activityHelperService.isActivityDisplayed(activity, this.filterData.Statuses, this.activities));

  goToActivity(activity, event: Event, conversationUnReadDiscussionClick: boolean = false) {
    // Fix for accessibility issue with md-menu in clickable tr
    if ((event.target as Element).nodeName === 'BUTTON') {
      return;
    }
    // Do not filter qual data by date if no keyword filter was applied on the activity overview
    if (this.hasAccess(activity)) {
      if (conversationUnReadDiscussionClick) {
        this.filterData.JumpToFirstNew = true;
      }
      this.filterData.ActivityType = activity.Type;
      const filterParams = this.activityFilterService.getQualStateParamsFromFilter(this.filterData, true);
      const stateName = this.$state.current.name;
      const referer = this.$state.href(stateName, {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
        ...filterParams,
      });
      this.$rootScope.$emit('sidenavActivityRefererChanged', this.$location.url());
      this.helperservice.goToEntity('activity', activity, {
        stateParams: {
          referer,
          ...filterParams,
        },
      });
    }
  }

  hasAccess(activity) {
    return !this.authService.isObserverUserLogin() || (activity.Status !== this.serverConstants.squareActivityStatusConstants.draft);
  }

  applyFilter() {
    this.filterData.Highlight = true;
    this.loadActivities();
  }

  resetFilter() {
    this.activityFilterService.resetQualFilterData(this.filterData, this.filterAdditionalData.MinStartDate);
    this.applyFilter();
    this.filterData.Highlight = false;
  }

  getMatchLabel(count) {
    return count === 1 ? 'MATCH' : 'MATCHES';
  }

  anyActivities() {
    return this.activities !== undefined && this.activities.length === 0;
  }

  anyMatchingConversations(activity) {
    return activity.ActivityConversationFilterResult.MatchingConversations !== -1;
  }

  anyMatchesCount() {
    return this.matches !== -1 && this.matchesFrom !== -1;
  }

  showArchivedButton(activity) {
    return this.authService.isHuman8UserLogin() && activity.ShowArchivedButton;
  }

  async statusFilterChange(value) {
    if (value.toString() !== this.filterData.Statuses.toString()) {
      this.filterData.Statuses = value;
      this.loadActivities();
    }
  }
}
