'use strict';

angular
  .module('insitesApp.squareconfig', [
    'insitesApp.squareconfig.splash',
    'insitesApp.squareconfig.mainPages',
    'insitesApp.squareconfig.incentives',
    'insitesApp.squareconfig.helpCenter',
    'insitesApp.squareconfig.design',
    'insitesApp.squareconfig.activationQuestions',
    'insitesApp.squareconfig.footer',
    'insitesApp.squareconfig.geofencing',
    'insitesApp.squareconfig.openregistration',
  ]);
