'use strict';

import { ParticipantService } from '../../../../core/dataservices/participant.service';
import { SquareService } from '../../../../core/dataservices/square.service';
import { Utils } from '../../../../core/utils';
import { ImageCropService } from '../../../../layout/image-crop/image-crop.service';
import * as _ from 'lodash';
import { SelectedSquareFactory } from '../../../../core/selectedsquare.factory';
import { FeatureService } from '../../../../core/dataservices/feature.service';
import { ParticipantFactory } from '../../../../core/participant.factory';
import { ConfigurationService } from '../../../../core/dataservices/configuration.service';
import { IFileConfigurationResponse } from '../../../../core/contracts/configuration.contracts';
import { AuthService } from 'src/app/core/dataservices/auth.service';

export class MemberDetailsController {
  static $inject = [
    '$stateParams',
    '$state',
    'participantservice',
    'participantFactory',
    'serverConstants',
    'imageCropService',
    '$transitions',
    'iscStimuliService',
    'selectedSquareFactory',
    'featureservice',
    'iscConfigurationService',
    'authService',
    'squareservice',
    '__env',
    '$sce'];

  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private $state: ng.ui.IStateService,
    private participantservice: ParticipantService,
    private participantFactory: ParticipantFactory,
    private serverConstants,
    private imageCropService: ImageCropService,
    private $transitions,
    private iscStimuliService,
    private selectedSquareFactory: SelectedSquareFactory,
    private featureService: FeatureService,
    private iscConfigurationService: ConfigurationService,
    private authService: AuthService,
    private squareservice: SquareService,
    private __env: Environment,
    private $sce: ng.ISCEService,
  ) { }

  currentNavItem: string;
  currentTabSelected;
  detailsRoute = 'root.square.members.details';
  avatar;
  isCallBtnDisabled: boolean;
  isCallBtnVisible: boolean;
  invalidSize = false;
  invalidType = false;
  profileCompletion: any = {
    Completed: 0,
    InProgress: 0,
    Target: 0,
  };
  participantGuid: string[];
  token: string;
  member = null;
  projectUrl: string;
  isAnonymousSquare = false;
  isSavingUpEnabled = false;
  imageStimuliMaxSizeBytes: number;
  fileTypeConstants;
  fileConfiguration: IFileConfigurationResponse[] = [];
  acceptedMimeTypes: string;
  acceptedPatterns: string;
  acceptedExtensions: string;
  squareGuid: string;

  async $onInit() {
    this.fileTypeConstants = this.iscConfigurationService.getFileTypes();
    this.iscConfigurationService.getFileConfiguration()
      .then((response) => {
        this.fileConfiguration = response;
        this.imageStimuliMaxSizeBytes = _.find(this.fileConfiguration, (config) =>
          config.FileType === this.fileTypeConstants.image).MaxFileSizeMb * Math.pow(1024, 2);

        const { mimeTypes, patterns, extensions } =
          this.iscConfigurationService.getAcceptedMimeTypesAndExtensions(this.fileConfiguration, [this.fileTypeConstants.image]);
        this.acceptedMimeTypes = mimeTypes.toString();
        this.acceptedPatterns = `'${patterns.toString()}'`;
        this.acceptedExtensions = extensions.toString();
      });

    this.loadMember();
    this.isAnonymousSquare = this.selectedSquareFactory.isSquareAnonymous;
    this.isCallBtnVisible = this.__env.showConnectButton && (this.authService.isHuman8UserLogin() || this.authService.isModeratorUserLogin());
    this.isCallBtnDisabled = (this.isCallBtnVisible) ? await this.isRequestCallBtnDisabled() : false;
    this.participantGuid = [this.$stateParams.memberGuid];
    this.token = this.authService.getToken();
    this.squareGuid = this.$stateParams.squareGuid;
    // Saving Up here is enabled only if
    //        Is enabled in master and at square level
    this.isSavingUpEnabled = await this.featureService.isFeatureEnabledForSquare(
      this.serverConstants.featureConstants.savingUpRewards);
    this.$transitions.onSuccess({}, () => {
      this.selectCurrentTabOrDefault();
    });
    this.$transitions.onStart({}, () => {
      this.selectCurrentTabOrDefault();
    });
  }

  trustUrl(url: string) {
    return this.$sce.trustAsResourceUrl(url);
  }

  selectCurrentTabOrDefault() {
    let currentTabSelected = _.replace(this.$state.current.name, `${this.detailsRoute}.`, '');
    if (currentTabSelected === this.detailsRoute) {
      currentTabSelected = 'profile';
    }
    this.currentNavItem = currentTabSelected;
  }

  loadMember() {
    this.participantservice.getSquareParticipantDetails(this.$stateParams.memberGuid)
      .then(async (participant) => {
        this.member = participant.data.Detail;
        await this.updateMemberDetails(this.member);
        this.updateMemberProgress();
        this.selectCurrentTabOrDefault();
      });
  }

  async isRequestCallBtnDisabled() {
    const connectProject = await this.squareservice.getSquareConnectProject(this.selectedSquareFactory.Guid);
    const guids = [this.$stateParams.memberGuid];

    if(connectProject == null) {
      return true;
    }

    this.projectUrl = connectProject.ProjectUrl;
    const availableSquareParticipants = await this.participantservice.areSquareParticipantsAvailableForCall(guids);
    if (!availableSquareParticipants) {
      return true;
    }
    const availableSquareParticipantKeys = Object.keys(availableSquareParticipants);
    return !availableSquareParticipantKeys || availableSquareParticipantKeys.length < 1;
  }

  async getAvatar(member) {
    if (member) {
      const image = member.HasProfileImage ?
        this.participantFactory.getParticipantImageUrl(member.Guid, member.DateModified) :
        ParticipantFactory.defaultAvatar;
      return await this.iscStimuliService.getImageOrFallback(image, ParticipantFactory.defaultAvatar);
    }
    return undefined;
  }

  goBackToMemberOverview() {
    this.$state.go('root.square.members.overview');
  }

  triggerClick(event) {
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent default action of Enter key
      event.target.click(); // Simulate a click event
    }
  }

  async selectFile($files) {
    this.invalidSize = false;
    this.invalidType = false;

    if ($files && $files.length > 0) {
      const file = $files.pop();
      this.invalidType = this.acceptedExtensions.indexOf(this.getExtensionFromFilename(file.name)) === -1
        || (file.type && this.acceptedMimeTypes.indexOf(file.type) === -1);
      this.invalidSize = file.size > this.imageStimuliMaxSizeBytes;

      if (!this.invalidSize && !this.invalidType) {
        const cropArea = await this.imageCropService.cropAvatar(file);
        const { data } = await this.participantservice.saveParticipantAvatar(file, cropArea, this.member.Guid);
        if (data) {
          this.member.DateModified = data.DateModified;
          this.member.HasProfileImage = true;
          this.avatar = await this.getAvatar(this.member);
          this.updateMemberProgress();
        }
      }
    }
  }

  getExtensionFromFilename(filename) {
    let result = '';
    if (filename) {
      result = filename.split('.').pop();
    }
    return result;
  }

  async updateMemberDetails(member) {
    this.member.FullName = this.member.FirstName + (this.member.LastName ? ` ${this.member.LastName}` : '');
    this.member.Username = this.member.Username || this.member.Email;
    this.member.RoleName = Utils.getRoleLabel(this.serverConstants.roleConstants, this.member.Role);
    this.member.StatusName = Utils.getStatusLabel(this.serverConstants.registrationStatusConstants, this.member.Status, this.member.Suspended);
    this.avatar = await this.getAvatar(member);
  }

  updateMemberProgress(): void {
    this.participantservice.getParticipantProgress(this.member.Guid).then((progress) => {
      this.profileCompletion = {
        Completed: Math.round(progress),
        InProgress: 0,
        Target: 0,
      };
    });
  }
}
