'use strict';

import './squareconfig.module';
import './splash';
import './design';
import './activationQuestions';
import './footer';
import './helpCenter';
import './incentives';
import './mainPages';
import './tabComponents';
import './geofencing';
import './openregistration';
import routeConfig from './squareconfig.route';

angular
  .module('insitesApp.squareconfig')
  .run(routeConfig);
